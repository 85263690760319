<template>
<!-- zx 2021 6 2 -->
<div>
  <!-- <div class="foot1"  v-lazy:background-image="require('./foot_shopping/banner.png')"> -->
  <div class="foot1" >
   
     <div class="start">
       <img v-lazy='require("./foot_shopping/jMlbDoUV.webp")' class="start_img">
     </div>
   <div class="bannert">
       <div class="written1">美食购物</div>
    <div class="written2">打造美食行业一站式服务平台，为用户提供美食资讯、特色小吃、
      特色菜、当地美食等内容，平台管理员可进行美食产品的发布、管理、销售，前台游客可进行美食抢购</div>
   </div>
    <div class="foot_1"></div>
  </div>
  <div class="foot2">
    <div class="written3">美食应用场景</div>
    <div class="written4"> 针对景区周围，美食城等美食集中地，或者有餐饮资源的商家，让餐饮商家入驻平台，形成自己的线上美食套餐，平台方进行美食销售</div>
    <div class="img-1">
      <img  v-lazy="require('./foot_shopping/a8FEmyX6.webp')" class="written5">
    </div>
  </div>
  <div class="foot3">
    <div class="written6">美食应用优势</div>
    <div class="written7" >
      <div class="written8">
        <div class="img-2">
          <img v-lazy="require('./foot_shopping/GmuMMD7T.webp')" class="written9">
        </div>
        <div class="written10">提供商家发布美食套餐，有效扩展线上销售渠道，建立新的盈利增长方式</div>
      </div>
       <div class="written8 ml30">
          <div class="img-2">
          <img v-lazy="require('./foot_shopping/I3VGHx98.webp')" class="written9">
        </div>
        <div class="written10">美食通过线上销售提升餐饮商家的知名度，品牌形象</div>
      </div>
       <div class="written8 ml30">
          <div class="img-2">
          <img v-lazy="require('./foot_shopping/CVdhvfqo.webp')" class="written9">
        </div>
        <div class="written10">通过线上销售为餐饮商家争取了准备食材的时间，避免就餐时间集中处理的人员紧缺，提升客户体验度</div>
      </div>
    </div>
  </div>
  <div class="foot4">
    <div  class="written6">美食应用解决方案</div>
    <!-- <div class="written11" v-lazy:background-image="require('./foot_shopping/img6.png')"> -->
    <div class="written11">
       <div class="img-7">
       <img v-lazy='require("./foot_shopping/6TS1YIcz.webp")' class="img-8">
     </div>
     <div class="literal2">
        <div class="written12">美食应用管理</div>
      <div class="written13">
        <div class="written14">
          <img src="./foot_shopping/ICON .png" class="written15">
          <div class="written16">美食管理</div>
        </div>
        <div class="written14 ml164">
          <img src="./foot_shopping/ICON(2).png" class="written15">
          <div class="written16">订单管理</div>
        </div>
        <div class="written14 ml164">
          <img src="./foot_shopping/ICON(1).png" class="written15">
          <div class="written16">供应商管理</div>
        </div>
      </div>
    </div>
     </div>
    <div class="written17">游客端</div>
    <div class="written18" >
      <div class="written19">
        <img src="./foot_shopping/icon (1).png" class="written20">
        <div class="written21">游客通过公众号、小程序、移动端进行美食抢购、优惠。</div>
      </div>
       <div class="written19 ml30">
        <img src="./foot_shopping/icon.png" class="written20">
        <div class="written21">支持游客优惠买单、美食套餐抢购、电话预约</div>
      </div>
    </div>
  </div>
  <!-- <div class="foot5">
    <img src="./foot_shopping/img.png" class="written22">
    <div class="written23">联合出品</div>
  </div> -->


<div>
  <Header></Header>
  <Footer></Footer>
  </div>
</div>
</template>
<script>
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
//引入jquery
export default {
components: {
    Header,
    Footer,
  },
}
</script>
<style lang="scss" scoped>
@keyframes sport {
  0% {
    transform: translateY(80px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
  .foot1{
     height: 600px;
    // background-repeat:  no-repeat;
    // background-size: 100% 100%;
  }
  .foot_1{
    position:absolute;
    top: 0;
    left: 0;
    height:600px;
    width:100%;
    background-color: rgba(0,0,0,.4);
  }
  .start_img{
   height: 600px;
  width: 100%;
}
.start{
   height: 600px;
  width: 100%;
  display: flex;
    justify-content: center;
    align-items: center;
    img[lazy="loading"] {
		width: 200px;
		height: 200px;
    

	}
	// 实际图片大小
	img[lazy="loaded"] {
		 height: 600px;
  width: 100%;
	}
}
.bannert{
  z-index: 5;
   position: absolute;
    left: 361px;
    top: 230px;

}
  .written1{
     color: rgba(255, 255, 255, 1);
    font-size: 48px;
    // margin-top: -370px;
    // padding-left: 360px;
    text-align: left;
    z-index:4
  }
  .written1{
     animation: sport 0.5s;
  transform: translateY(0px);
  }
  .written2{
    color: rgba(255, 255, 255, 1);
    font-size: 24px;
    // padding-left: 360px;
    margin-top: 24px;
    text-align: left;
    width: 675px;
    z-index:999
  }
  .written2{
     animation: sport 0.5s;
  transform: translateY(0px);
  }
  .written3{
  color: rgba(51, 51, 51, 1);
  font-size: 40px;
  margin-top: 80px;
  }
  .written4{
  color: rgba(86, 86, 86, 1);
  font-size: 20px;
  padding-top: 24px;
  width: 1053px;
  line-height: 40px;
  margin: auto;
  }
  .written5{
    width: 1200px;
    height: 420px;
   
  }
  .img-1{
     width: 1200px;
    height: 420px;
    margin: auto;
     margin-top: 60px;
    margin-bottom: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    img[lazy="loading"] {
		width: 150px;
		height: 150px;
	}
	// 实际图片大小
	img[lazy="loaded"] {
		width: 1200px;
    height: 420px;
	}
  }
  .foot3{
    background-color: #F6F8FB;
  }
  .written6{
  color: rgba(51, 51, 51, 1);
  font-size: 40px;
  padding-top: 80px;
  }
  .written8{
  height: 392px;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
  width: 380px;
  }
  .written7{
    display: flex;
    justify-content: center;
    margin-top: 60px;
    padding-bottom: 100px;
  }
  .written9{
    width: 380px;
    height: 260px;
  }
  .img-2{
     width: 380px;
    height: 260px;
    display: flex;
    justify-content: center;
    align-items: center;
    img[lazy="loading"] {
		width: 50px;
		height: 50px;
	}
	// 实际图片大小
	img[lazy="loaded"] {
		 width: 380px;
    height: 260px;
	}
  }
  .literal2{
    position:  absolute;
    top: 124px;
    left: 179px;
  }
  .written10{
  width: 332px;
  color: rgba(153, 153, 153, 1);
  font-size: 20px;
  text-align: left;
  margin-top: 24px;
  margin-left: 24px;
  }
  .ml30{
    margin-left: 30px;
  }
  .written11{
    width: 1200px;
     height: 420px;
    // background-repeat:  no-repeat ;
    // background-size: 100% 100%;
    margin: auto;
    margin-top: 60px;
    margin-bottom: 60px;
    position: relative;
  }
.img-8{
   width: 1200px;
     height: 420px;
}
.img-7{
   width: 1200px;
     height: 420px;
      display: flex;
    justify-content: center;
    align-items: center;
    img[lazy="loading"] {
		width: 110px;
		height: 110px;
	}
	// 实际图片大小
	img[lazy="loaded"] {
		 width: 1200px;
     height: 420px;
	}
}
  .written12{
  color: rgba(255, 255, 255, 1);
  font-size: 32px;
  // padding-top: 124px;
  }
  .written13{
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
  .written14{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .written15{
    width: 56px;
    height: 56px;
  }
  .written16{
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  margin-left: 24px;
  }
  .ml164{
    margin-left: 164px;
  }
  .written17{
    color: rgba(51, 51, 51, 1);
  font-size: 32px;
  margin-top: 60px;
  }
  .written18{
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  .written19{
  height: 150px;
  border-radius: 0 0 6px 6px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
  width: 585px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
  }
  .written20{
    width: 56px;
    height: 56px;
  }
  .written21{
    color: rgba(86, 86, 86, 1);
  font-size: 20px;
  margin-left: 32px;
   width: 417px;
   text-align: left;
  }
  .foot5{
    height: 247px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .written22{
    width: 359px;
    height: 47px;
  }
  .written23{
  color: rgba(51, 51, 51, 1);
  font-size: 32px;
  }
</style>